<template>
  <div>
    <KTCodePreview v-bind:title="'Tạo mới quyền'">
      <template v-slot:preview>
        <v-form ref="form" lazy-validation>
          <div class="col-md-12 mb-5">
            <div class="row">
              <div class="col-md-3">
                <b-row class="my-1">
                  <label class="label-input">Mã quyền:</label>
                  <span class="text-danger">*</span>
                </b-row>
                <b-row class="my-1">
                  <b-input
                    size="sm"
                    placeholder="Nhập mã quyền"
                    v-model="$v.form.code.$model"
                    :state="validateState('code')"
                    append-icon="search"
                    single-line
                    hide-details
                    class="col-md-12 form-control-sm"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-input>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Nhập mã với ít nhất 3 ký tự!
                  </b-form-invalid-feedback>
                </b-row>
              </div>
              <div class="col-md-3">
                <b-row class="my-1">
                  <b-col>
                    <label class="label-input">Tên quyền:</label>
                    <span class="text-danger">*</span>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col>
                    <b-input
                      placeholder="Nhập tên quyền"
                      append-icon="search"
                      single-line
                      hide-details
                      class="col-md-12 form-control-sm"
                      v-model="$v.form.name.$model"
                      :state="validateState('name')"
                      aria-describedby="input-live-help input-name-feedback"
                    ></b-input>
                    <b-form-invalid-feedback id="input-name-feedback">
                      Nhập tên với ít nhất 3 ký tự!
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <p class="font-weight-bold">
              <strong style="font-size: 14px">1. Cài đặt chung</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý người dùng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionUserPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý nhân sự</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listEmployeePermission"
                    :options="optionEmployeePermission"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý quyền</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listRolePermission"
                    :options="optionRolePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">2. Danh mục chung</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý cửa hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listStorePermission"
                    :options="optionStorePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý nguồn đơn hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listOrderSourcePermission"
                    :options="optionOrderSourcePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý doanh nghiệp</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listOrderSourcePermission"
                    :options="optionCompanyPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý khu vực</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listOrderSourcePermission"
                    :options="optionAreaPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý vùng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listOrderSourcePermission"
                    :options="optionRegionPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">3. Khách hàng</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý khách hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listCustomerPermission"
                    :options="optionCustomerPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý CSKH</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionCustomerCarePermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý điểm tích lũy</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPointPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">4. Sản phẩm</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý sản phẩm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listProductPermission"
                    :options="optionProductPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý danh mục sản phẩm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listCatePermission"
                    :options="optionCatePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong
                    >Quản lý phiếu yêu cầu thay đổi danh mục sản phẩm</strong
                  >
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listCategoryApprovals"
                    :options="optionCategoryApprovals"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý nhà cung cấp</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listProviderPermission"
                    :options="optionProviderPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý thuộc tính sản phẩm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listPropertyPermission"
                    :options="optionPropertyPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý thương hiệu</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listBrandPermission"
                    :options="optionBrandPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý IMEI</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionImeiPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">5. Kho</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý kho hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listStockPermission"
                    :options="optionStockPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý phiếu yêu cầu nhập hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPOPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý nhập kho</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionStockImport"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý xuất kho</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionStockExport"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý vận chuyển</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionShipment"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý phiếu yêu cầu xnk</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionStockRequire"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý kiểm kê tồn kho</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionStockCheckPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý truy thu tồn kho</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionDebtStockPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">6. Đơn hàng</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý đơn hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionOrderPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">7. Bán hàng</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý bán hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listBillPermission"
                    :options="optionBillPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý trade-in</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTradeInPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý hóa đơn đỏ</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionVatInvoicePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">8. Kế toán</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý tài khoản kế toán</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionAccountantPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý công nợ</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionDebtPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý bút toán</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTransactionPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý tài khoản trả góp</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionInstallmentPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý danh mục thu chi</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTransactionCatePermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý phiếu duyệt chi</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionApprovalPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">9. Hoa hồng</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý thưởng hoa hồng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionRewardPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Xem báo cáo thưởng hoa hồng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionRewardReportPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Tính lương</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionSalaryPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">10. Báo cáo</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Xem báo cáo</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionReportPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Báo cáo POSM</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPosmReportPermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý Campaign POSM</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionCampaignPermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý Form POSM</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPosmFormPermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Danh sách các Form POSM đã được điền</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPosmSubmissionPermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">11. Cấu hình</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Bộ phụ kiện tặng kèm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionProductAttachPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Bộ phụ kiện khuyến mãi</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionProductPromotionPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Gói bảo hành</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    :id="newId()"
                    v-model="listUserPermission"
                    :options="optionWarrantyPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Chiết khấu</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionDiscountPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Thu cũ- đổi mới</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTradeInProgramPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Coupon</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionCouponProgramPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Tồn ảo sản phẩm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionVirtualStockPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Lý do bảo hành</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionWarrantyReasonPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý deal sheet</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionDealSheetPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý cấu hình duyệt chi</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionApprovalConfigPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý cấu hình chính sách gói BHMR</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionWarrantyBenfitPolicyPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý ca làm việc</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionShiftWorkPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý cấu hình ca làm việc</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionShiftWorkConfigPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý bộ phận/ phòng ban</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionDepartmentPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Giỏ hàng ưu tiên</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPriorityCart"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4"></div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Phiếu hỗ trợ</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTicket"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý truy cập</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionUserAccessPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Phiếu chuyển điểm tiêu dùng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPointTransfer"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Giới hạn xuất excel</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionUserLimitExport"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Phiếu yêu cầu nhập hàng về kho</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionStorePurchaseOrder"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Cấu hình ngành hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPurchasingDepartment"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Giao dịch các sản phẩm phần mềm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionSoftwareProductProvider"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4" v-if="showVieOnPermissionOptions()">
                <p class="font-weight-bold">
                  <strong>Giao dịch các sản phẩm dịch vụ</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionVieonProductProvider"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Cấu hình tính năng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionFeatureFlag"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Cấu hình user token</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionUserToken"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Cấu hình form đặt trước</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPreOrderForm"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </v-form>
      </template>

      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          type="submit"
          @click="createRole"
          >Lưu
        </b-button>
        <router-link :to="'/permissions'">
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy
          </b-button>
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}

.label-input {
  font-weight: 500;
}

.custom-checkbox .custom-checkbox {
  display: block !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { v4 } from 'uuid';
import {
  isFeatureActive,
} from '@/utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      form: {
        code: '',
        name: '',
      },
      listUserPermission: [],
      optionUserPermission: [
        { text: 'Xem', value: 'userView' },
        { text: 'Thêm', value: 'userInsert' },
        { text: 'Cập nhật', value: 'userUpdate' },
        { text: 'Xóa', value: 'userDelete' },
      ],
      listStorePermission: [],
      optionStorePermission: [
        { text: 'Xem', value: 'storeView' },
        { text: 'Thêm', value: 'storeInsert' },
        { text: 'Cập nhật', value: 'storeUpdate' },
        { text: 'Xóa', value: 'storeDelete' },
      ],
      listRolePermission: [],
      optionRolePermission: [
        { text: 'Xem', value: 'roleView' },
        { text: 'Thêm', value: 'roleInsert' },
        { text: 'Cập nhật', value: 'roleUpdate' },
        { text: 'Xóa', value: 'roleDelete' },
      ],
      listOrderSourcePermission: [],
      optionOrderSourcePermission: [
        { text: 'Xem', value: 'orderSourceView' },
        { text: 'Thêm', value: 'orderSourceInsert' },
        { text: 'Cập nhật', value: 'orderSourceUpdate' },
        { text: 'Xóa', value: 'orderSourceDelete' },
      ],
      listEmployeePermission: [],
      optionEmployeePermission: [
        { text: 'Xem', value: 'employeeView' },
        { text: 'Thêm', value: 'employeeInsert' },
        { text: 'Cập nhật', value: 'employeeUpdate' },
        { text: 'Xóa', value: 'employeeDelete' },
      ],
      listCustomerPermission: [],
      optionCustomerPermission: [
        { text: 'Xem', value: 'customerView' },
        { text: 'Thêm', value: 'customerInsert' },
        { text: 'Cập nhật', value: 'customerUpdate' },
        { text: 'Xóa', value: 'customerDelete' },
        { text: 'Xuất file excel', value: 'customerExport' },
        {
          text: 'Xem số điện thoại trong BlackList',
          value: 'customerBlackListView',
        },
        {
          text: 'Thêm số điện thoại vào BlackList',
          value: 'customerBlackListCreate',
        },
        {
          text: 'Cập nhật số điện thoại trong BlackList',
          value: 'customerBlackListUpdate',
        },
      ],
      listCatePermission: [],
      optionCatePermission: [
        { text: 'Xem', value: 'cateView' },
        { text: 'Thêm', value: 'cateInsert' },
        { text: 'Cập nhật', value: 'cateUpdate' },
        { text: 'Xóa', value: 'cateDelete' },
      ],
      listBrandPermission: [],
      optionBrandPermission: [
        { text: 'Xem', value: 'brandView' },
        { text: 'Thêm', value: 'brandInsert' },
        { text: 'Cập nhật', value: 'brandUpdate' },
        { text: 'Xóa', value: 'brandDelete' },
      ],
      listProviderPermission: [],
      optionProviderPermission: [
        { text: 'Xem', value: 'providerView' },
        { text: 'Thêm', value: 'providerInsert' },
        { text: 'Cập nhật', value: 'providerUpdate' },
        { text: 'Xóa', value: 'providerDelete' },
      ],
      listPropertyPermission: [],
      optionPropertyPermission: [
        { text: 'Xem', value: 'propertyView' },
        { text: 'Thêm', value: 'propertyInsert' },
        { text: 'Cập nhật', value: 'propertyUpdate' },
        { text: 'Xóa', value: 'propertyDelete' },
      ],
      listProductPermission: [],
      optionProductPermission: [
        { text: 'Xem', value: 'productView' },
        { text: 'Thêm', value: 'productInsert' },
        { text: 'Cập nhật', value: 'productUpdate' },
        { text: 'Xóa', value: 'productDelete' },
        { text: 'Xem giá nhập', value: 'productOriginalPriceView' },
        { text: 'Thông tin VAT', value: 'productVatUpsert' },
        { text: 'Cập nhật Mã sản phẩm', value: 'productCodeUpdate' },
        { text: 'Cập nhật giá', value: 'productUpdatePrice' },
        { text: 'Xuất excel', value: 'productExport' },
        { text: 'Xem sản phẩm phần mềm', value: 'mkoGetProducts' },
        // { text: 'Xem sản phẩm dịch vụ', value: 'productVieOnView' },
        { text: 'Xuất excel tuổi tồn', value: 'productStorageTimeExport' },
      ],
      listStockPermission: [],
      optionStockPermission: [
        { text: 'Xem', value: 'stockView' },
        { text: 'Giới hạn xem tồn kho', value: 'stockSearchProduct' },
        { text: 'Thêm', value: 'stockInsert' },
        { text: 'Cập nhật', value: 'stockUpdate' },
        { text: 'Xóa', value: 'stockDelete' },
        { text: 'Hủy duyệt', value: 'stockCancelTransfer' },
        { text: 'Xóa có lý do', value: 'stockDeleteTransfer' },
        { text: 'Lưu duyệt', value: 'stockApproveTransfer' },
        { text: 'Cân kho', value: 'stockAdjust' },
        { text: 'Xuất excel', value: 'stockExport' },
      ],
      listBillPermission: [],
      optionBillPermission: [
        { text: 'Xem', value: 'billView' },
        { text: 'Thêm', value: 'billInsert' },
        { text: 'Cập nhật', value: 'billUpdate' },
        { text: 'Xóa', value: 'billDelete' },
        { text: 'Cập nhật vượt quá số ngày', value: 'billUpdateUnlimit' },
        { text: 'Trả hàng vượt quá số ngày', value: 'billInsertReturnUnlimit' },
        { text: 'Chỉnh sửa giá sản phẩm', value: 'billEditProductPrice' },
        { text: 'Xuất excel', value: 'billExport' },
        { text: 'Xem đơn hàng tất cả cửa hàng', value: 'billViewAll' },
        {
          text: 'Xem đầy đủ mã imei đính kèm (bảo hành)',
          value: 'billViewFullExternalImeiNo',
        },
      ],
      listVatInvoicePermission: [],
      optionVatInvoicePermission: [
        { text: 'Xem', value: 'vatInvoiceView' },
        { text: 'Thêm', value: 'vatInvoiceInsert' },
        { text: 'Cập nhật', value: 'vatInvoiceUpdate' },
        { text: 'Đẩy qua mifi', value: 'vatInvoiceExportMifi' },
        { text: 'Xóa', value: 'vatInvoiceDelete' },
        {
          text: 'Cho phép xuất HĐ quá hạn',
          value: 'vatInvoiceExportMifiExceedExpired',
        },
      ],
      optionTransactionPermision: [
        { text: 'Xem', value: 'transactionView' },
        { text: 'Thêm', value: 'transactionInsert' },
        { text: 'Cập nhật', value: 'transactionUpdate' },
        { text: 'Xóa', value: 'transactionDelete' },
        { text: 'Nhập excel ', value: 'transactionImportExcel' },
        { text: 'Thu chi lùi ngày', value: 'transactionPayPrevDay' },
      ],
      optionInstallmentPermision: [
        { text: 'Xem', value: 'installmentView' },
        { text: 'Thêm', value: 'installmentInsert' },
        { text: 'Cập nhật', value: 'installmentUpdate' },
        { text: 'Xóa', value: 'installmentDelete' },
      ],
      optionAccountantPermision: [
        { text: 'Xem', value: 'accountantView' },
        { text: 'Thêm', value: 'accountantInsert' },
        { text: 'Cập nhật', value: 'accountantUpdate' },
        { text: 'Xóa', value: 'accountantDelete' },
      ],
      optionDebtPermision: [
        { text: 'Xem công nợ nhà cung cấp', value: 'debtProviderView' },
        { text: 'Xem công nợ trả góp', value: 'debtInstallmentView' },
        { text: 'Xem công nợ quẹt thẻ', value: 'debtCreditView' },
      ],
      optionOrderPermision: [
        { text: 'Xem', value: 'orderView' },
        { text: 'Thêm', value: 'orderInsert' },
        { text: 'Cập nhật', value: 'orderUpdate' },
        { text: 'Xóa', value: 'orderDelete' },
        { text: 'Cập nhật cho kế toán', value: 'orderUpdateAccountant' },
        { text: 'Xuất excel đơn hàng', value: 'orderExport' },
      ],
      optionCustomerCarePermision: [
        { text: 'Xem', value: 'cusCareView' },
        { text: 'Thêm', value: 'cusCareInsert' },
        { text: 'Cập nhật', value: 'cusCareUpdate' },
        { text: 'Xóa', value: 'cusCareDelete' },
      ],
      optionPointPermision: [
        { text: 'Xem', value: 'pointView' },
        { text: 'Thêm', value: 'pointInsert' },
        { text: 'Cập nhật', value: 'pointUpdate' },
        { text: 'Xóa', value: 'pointDelete' },
      ],
      isNew: true,
      optionTradeInPermision: [
        { text: 'Xem', value: 'tradeInView' },
        { text: 'Thêm', value: 'tradeInInsert' },
        { text: 'Cập nhật', value: 'tradeInUpdate' },
        { text: 'Xóa', value: 'tradeInDelete' },
        {
          text: 'Xem phiếu định giá tất cả cửa hàng',
          value: 'tradeInShowAllStore',
        },
        { text: 'Xuất excel', value: 'tradeInExport' },
        {
          text: 'Nhập excel sản phẩm vào phiếu thu cũ',
          value: 'tradeInImportProductsExcel',
        },
        {
          text: 'Chỉnh sửa giá đồng loạt vào phiếu thu cũ',
          value: 'tradeInUpdateProductsExcel',
        },
      ],
      optionRewardPermision: [
        { text: 'Xem', value: 'rewardView' },
        { text: 'Thêm', value: 'rewardInsert' },
        { text: 'Cập nhật', value: 'rewardUpdate' },
        { text: 'Xóa', value: 'rewardDelete' },
      ],
      optionRewardReportPermision: [
        { text: 'Tất cả', value: 'rrViewAll' },
        { text: 'Cá nhân', value: 'rrViewByEmp' },
        { text: 'Danh sách hóa đơn', value: 'rrViewBill' },
      ],
      optionProductAttachPermision: [
        { text: 'Xem', value: 'proAcctachView' },
        { text: 'Thêm', value: 'proAcctachInsert' },
        { text: 'Cập nhật', value: 'proAcctachUpdate' },
        { text: 'Xóa', value: 'proAcctachDelete' },
      ],
      optionProductPromotionPermision: [
        { text: 'Xem', value: 'proPromotionView' },
        { text: 'Thêm', value: 'proPromotionInsert' },
        { text: 'Cập nhật', value: 'proPromotionUpdate' },
        { text: 'Xóa', value: 'proPromotionDelete' },
      ],
      optionWarrantyPermision: [
        { text: 'Xem', value: 'warrantyView' },
        { text: 'Thêm', value: 'warrantyInsert' },
        { text: 'Cập nhật', value: 'warrantyUpdate' },
        { text: 'Xóa', value: 'warrantyDelete' },
      ],
      optionDiscountPermision: [
        { text: 'Xem', value: 'discountView' },
        { text: 'Thêm', value: 'discountInsert' },
        { text: 'Cập nhật', value: 'discountUpdate' },
        { text: 'Xóa', value: 'discountDelete' },
      ],
      optionTradeInProgramPermision: [
        { text: 'Xem', value: 'programTradeInView' },
        { text: 'Thêm', value: 'programTradeInInsert' },
        { text: 'Cập nhật', value: 'programTradeInUpdate' },
        { text: 'Xóa', value: 'programTradeInDelete' },
      ],
      optionCompanyPermision: [
        { text: 'Xem', value: 'companyView' },
        { text: 'Thêm', value: 'companyInsert' },
        { text: 'Cập nhật', value: 'companyUpdate' },
        { text: 'Xóa', value: 'companyDelete' },
      ],
      optionAreaPermision: [
        { text: 'Xem', value: 'areaView' },
        { text: 'Thêm', value: 'areaInsert' },
        { text: 'Cập nhật', value: 'areaUpdate' },
        { text: 'Xóa', value: 'areaDelete' },
      ],
      optionRegionPermision: [
        { text: 'Xem', value: 'regionView' },
        { text: 'Thêm', value: 'regionInsert' },
        { text: 'Cập nhật', value: 'regionUpdate' },
        { text: 'Xóa', value: 'regionDelete' },
      ],
      optionTransactionCatePermision: [
        { text: 'Xem', value: 'transCateView' },
        { text: 'Thêm', value: 'transCateInsert' },
        { text: 'Cập nhật', value: 'transCateUpdate' },
        { text: 'Xóa', value: 'transCateDelete' },
      ],
      optionReportPermision: [{ text: 'Có', value: 'reportView' }],
      optionCouponProgramPermision: [
        { text: 'Xem', value: 'couponView' },
        { text: 'Thêm', value: 'couponInsert' },
        { text: 'Cập nhật', value: 'couponUpdate' },
        { text: 'Xóa', value: 'couponDelete' },
        { text: 'Coupon áp dụng tất cả SP', value: 'couponApplyAllProduct' },
        {
          text: 'Coupon áp dụng tất cả khách hàng',
          value: 'couponApplyAllCustomer',
        },
      ],
      optionVirtualStockPermision: [
        { text: 'Xem', value: 'virtualStockView' },
        { text: 'Thêm', value: 'virtualStockInsert' },
        { text: 'Cập nhật', value: 'virtualStockUpdate' },
        { text: 'Xóa', value: 'virtualStockDelete' },
      ],
      optionWarrantyReasonPermision: [
        { text: 'Xem', value: 'warrantyReasonConfigView' },
        { text: 'Thêm', value: 'warrantyReasonConfigCreate' },
        { text: 'Cập nhật', value: 'warrantyReasonConfigUpdate' },
        { text: 'Xóa', value: 'warrantyReasonConfigDelete' },
      ],
      optionPOPermision: [
        { text: 'Xem', value: 'poView' },
        { text: 'Xem tất cả', value: 'poViewAll' },
        { text: 'Thêm', value: 'poInsert' },
        { text: 'Cập nhật', value: 'poUpdate' },
        { text: 'Xóa', value: 'poDelete' },
        { text: 'Duyệt', value: 'poPurchaseApprove' },
        { text: 'Xác nhận', value: 'poAccountanApprove' },
        { text: 'Hủy phiếu', value: 'poCancel' },
        { text: 'Nhập hàng', value: 'poImportStock' },
        { text: 'Cửa hàng xác nhận', value: 'poStoreApprove' },
      ],
      productPricePermission: [
        { text: 'Xem', value: 'productPriceView' },
        { text: 'Thêm', value: 'productPriceInsert' },
        { text: 'Cập nhật', value: 'productPriceUpdate' },
        { text: 'Xóa', value: 'productPriceDelete' },
      ],
      optionImeiPermission: [
        { text: 'Xem báo cáo kích hoạt', value: 'imeiActiveReportView' },
        { text: 'Kích hoạt', value: 'activeImei' },
        { text: 'Kích hoạt trên hóa đơn', value: 'activeImeiBill' },
        { text: 'Xem lịch sử', value: 'imeiHistoryView' },
      ],
      optionStockImport: [
        { text: 'Xem', value: 'stockImportView' },
        { text: 'Thêm', value: 'stockImportInsert' },
        { text: 'Cập nhật', value: 'stockImportUpdate' },
        { text: 'Xóa', value: 'stockImportDelete' },
      ],
      optionStockExport: [
        { text: 'Xem', value: 'stockExportView' },
        { text: 'Thêm', value: 'stockExportInsert' },
        { text: 'Cập nhật', value: 'stockExportUpdate' },
        { text: 'Xóa', value: 'stockExportDelete' },
      ],
      optionShipment: [
        { text: 'Xem', value: 'shipmentView' },
        { text: 'Thêm', value: 'shipmentInsert' },
        { text: 'Cập nhật', value: 'shipmentUpdate' },
        { text: 'Xóa', value: 'shipmentDelete' },
        { text: 'Xuất báo cáo', value: 'shipmentExport' },
      ],
      optionStockRequire: [
        {
          text: 'Hiển thị nút phát hành vận chuyển đi đường',
          value: 'portalFastRequireStockViewToolBar',
        },
        {
          text: 'Phát hành phiếu vận chuyển đi đường',
          value: 'portalFastRequireStockIssue',
        },
      ],
      optionApprovalPermision: [
        { text: 'Xem', value: 'approvalView' },
        { text: 'Thêm', value: 'approvalInsert' },
        { text: 'Cập nhật', value: 'approvalUpdate' },
        { text: 'Xóa', value: 'approvalDelete' },
        { text: 'Cập nhật thanh toán', value: 'approvalPaid' },
        { text: 'Duyệt', value: 'approvalApprove' },
      ],
      optionApprovalConfigPermision: [
        { text: 'Xem', value: 'approvalConfigView' },
        { text: 'Thêm', value: 'approvalConfigInsert' },
        { text: 'Cập nhật', value: 'approvalConfigUpdate' },
        { text: 'Xóa', value: 'approvalConfigDelete' },
      ],
      optionWarrantyBenfitPolicyPermision: [
        { text: 'Thêm/chỉnh sửa', value: 'warrantyBenefitPolicyCreate' },
        { text: 'Xóa', value: 'warrantyBenefitPolicyDelete' },
      ],
      optionTransferStorePermission: [
        { text: 'Xem', value: 'transferStoreView' },
        { text: 'Thêm', value: 'transferStoreInsert' },
        { text: 'Cập nhật', value: 'transferStoreUpdate' },
        { text: 'Xóa', value: 'transferStoreDelete' },
      ],
      optionShiftWorkConfigPermission: [
        { text: 'Xem', value: 'shiftWorkConfigView' },
        { text: 'Thêm', value: 'shiftWorkConfigInsert' },
        { text: 'Cập nhật', value: 'shiftWorkConfigUpdate' },
        { text: 'Xóa', value: 'shiftWorkConfigDelete' },
      ],
      optionShiftWorkPermission: [
        { text: 'Xem', value: 'shiftWorkView' },
        { text: 'Thêm', value: 'shiftWorkInsert' },
        { text: 'Cập nhật', value: 'shiftWorkUpdate' },
        { text: 'Xóa', value: 'shiftWorkDelete' },
      ],
      optionSalaryPermision: [{ text: 'Có', value: 'salaryReport' }],
      optionDepartmentPermission: [
        { text: 'Xem', value: 'departmentView' },
        { text: 'Thêm', value: 'departmentInsert' },
        { text: 'Cập nhật', value: 'departmentUpdate' },
        { text: 'Xóa', value: 'departmentDelete' },
      ],
      optionStockCheckPermission: [
        { text: 'Xem', value: 'stockCheckView' },
        { text: 'Xem chi tiết', value: 'stockCheckViewDetail' },
        { text: 'Xem danh sách', value: 'stockCheckViewList' },
        { text: 'Thêm mới phiếu', value: 'stockCheckInsert' },
        { text: 'Thêm sản phẩm vào giỏ', value: 'stockCheckUpdateProduct' },
        { text: 'Cập nhật', value: 'stockCheckUpdate' },
        { text: 'Lưu', value: 'stockCheckSave' },
        { text: 'Xác nhận phiếu', value: 'stockCheckConfirm' },
        { text: 'Xóa', value: 'stockCheckDelete' },
      ],
      optionDebtStockPermission: [
        { text: 'Xem', value: 'debtStockView' },
        { text: 'Thêm', value: 'debtStockInsert' },
        { text: 'Cập nhật', value: 'debtStockUpdate' },
        { text: 'Xóa', value: 'debtStockDelete' },
      ],
      optionCampaignPermissions: [
        { text: 'Xem', value: 'campaignView' },
        { text: 'Thêm', value: 'campaignInsert' },
        { text: 'Cập nhật', value: 'campaignUpdate' },
        { text: 'Xóa', value: 'campaignDelete' },
        { text: 'Xuất Excel', value: 'campaignExcelExport' },
      ],
      optionPosmFormPermissions: [
        { text: 'Xem', value: 'posmFormView' },
        { text: 'Thêm', value: 'posmFormInsert' },
        { text: 'Cập nhật', value: 'posmFormUpdate' },
        { text: 'Xóa', value: 'posmFormDelete' },
        { text: 'Nhập Excel', value: 'posmFormExcelImport' },
      ],
      optionPosmSubmissionPermissions: [
        { text: 'Xem', value: 'posmFormSubmissionView' },
      ],
      optionPosmReportPermissions: [{ text: 'Có', value: 'posmReportInsert' }],
      optionDealSheetPermision: [
        { text: 'Xem', value: 'dealsheetView' },
        { text: 'Thêm', value: 'dealsheetInsert' },
        { text: 'Cập nhật', value: 'dealsheetUpdate' },
        { text: 'Xóa', value: 'dealsheetDelete' },
      ],
      optionPriorityCart: [
        { text: 'Xem', value: 'priorityCartView' },
        { text: 'Thêm', value: 'priorityCartInsert' },
        { text: 'Cập nhật', value: 'priorityCartUpdate' },
        { text: 'Xóa', value: 'priorityCartDelete' },
      ],
      optionTicket: [
        { text: 'Xem tất cả phiếu', value: 'ticketViewAll' },
        { text: 'Câu hình phiếu', value: 'ticketSetting' },
      ],
      optionPointTransfer: [
        { text: 'Xem tất cả', value: 'pointTransferViewAll' },
        {
          text: 'Xem của cửa hàng',
          value: 'pointTransferView',
        },
        { text: 'Duyệt chuyển điểm', value: 'pointTransferUpdate' },
      ],
      optionStorePurchaseOrder: [
        { text: 'Xem', value: 'storePOView' },
        { text: 'Xem tất cả', value: 'storePOViewAll' },
        { text: 'Tạo phiếu', value: 'storePOCreate' },
        { text: 'Duyệt phiếu', value: 'storePOApprove' },
        { text: 'Huỷ phiếu', value: 'storePOCancel' },
        { text: 'Cửa hàng xác nhận', value: 'storePOConfirm' },
        { text: 'Xoá phiếu', value: 'storePODelete' },
      ],
      optionUserAccessPermission: [
        { text: 'Xem', value: 'userAccessView' },
        { text: 'Thêm', value: 'userAccessInsert' },
        { text: 'Cập nhật', value: 'userAccessUpdate' },
        { text: 'Xóa', value: 'userAccessDelete' },
      ],
      optionUserLimitExport: [
        { text: '30 ngày', value: 'exportWithin30Day' },
        { text: '60 ngày', value: 'exportWithin60Day' },
        { text: '90 ngày', value: 'exportWithin90Day' },
      ],
      optionReportHistory: [
        { text: 'Xem lịch sử xuất excel', value: 'reportHistoryView' },
      ],
      optionPurchasingDepartment: [
        { text: 'Xem', value: 'purchasingDepartmentView' },
        { text: 'Thêm mới', value: 'purchasingDepartmentCreate' },
        { text: 'Xoá', value: 'purchasingDepartmentDelete' },
      ],
      optionSoftwareProductProvider: [
        { text: 'Xem', value: 'mkoGetTransactions' },
      ],
      optionVieonProductProvider: [
        { text: 'Xem', value: 'vieOnTransactionView' },
      ],
      optionFeatureFlag: [
        { text: 'Xem', value: 'featureFlagView' },
        { text: 'Thêm', value: 'featureFlagCreate' },
        { text: 'Sửa', value: 'featureFlagUpdate' },
        { text: 'Xóa', value: 'featureFlagDelete' },
      ],
      optionUserToken: [
        { text: 'Xem', value: 'userTokenView' },
        { text: 'Thu hồi token', value: 'userTokenRevoke' },
      ],
      listCategoryApprovals: [],
      optionCategoryApprovals: [
        { text: 'Xem danh sách phiếu yêu cầu', value: 'cateApprovalView' },
        { text: 'Xem thông tin danh mục', value: 'cateApprovalViewOnly' },
        { text: 'Xác nhận', value: 'cateApprovalApprove' },
        { text: 'Thêm phiếu tạo', value: 'cateApprovalInsertCreate' },
        { text: 'Thêm phiếu cập nhật', value: 'cateApprovalInsertUpdate' },
        { text: 'Thêm phiếu gộp', value: 'cateApprovalInsertMerge' },
        { text: 'Thêm phiếu di chuyển', value: 'cateApprovalInsertMove' },
        { text: 'Thêm phiếu xóa', value: 'cateApprovalInsertDelete' },
        { text: 'Xóa phiếu yêu cầu', value: 'cateApprovalDelete' },
      ],
      optionPreOrderForm: [
        { text: 'Tạo', value: 'preOrderFormCreate' },
        { text: 'Cập nhật', value: 'preOrderFormUpdate' },
      ],
      isEnableVieOnPermission: false,
    };
  },
  validations: {
    form: {
      code: {
        required,
        minLength: minLength(3),
      },
      name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  components: {
    KTCodePreview,
  },
  computed: {
    nameState() {
      return this.name.length > 2;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phân quyền', route: '/permissions' },
      { title: 'Danh sách quyền', route: '/permissions' },
      { title: 'Tạo mới quyền' },
    ]);
  },
  async created(){
    this.isEnableVieOnPermission = await isFeatureActive('add-vieon-sub');

    if (this.isEnableVieOnPermission) {
      this.optionProductPermission.push({
        text: 'Xem sản phẩm dịch vụ',
        value: 'productVieOnView'
      });
    }
},
  methods: {
    newId() {
      return v4();
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    createRole: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // this.listUserPermission.concat(this.listStorePermission);
      this.listStorePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listRolePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listOrderSourcePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listEmployeePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });

      this.listOrderSourcePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });

      this.listCustomerPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });

      this.listCatePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listBrandPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listProviderPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listPropertyPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listProductPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listStockPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listBillPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listVatInvoicePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listCategoryApprovals.forEach((element) => {
        this.listUserPermission.push(element);
      });

      const code = this.$v.form.code.$model;
      const name = this.$v.form.name.$model;
      let data = {
        code: code,
        name: name,
        listFeature: this.listUserPermission,
      };
      ApiService.post('role/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess('Thêm mới quyền thành công!');
            setTimeout(() => {
              this.$router.push({
                path: '/permissions',
              });
            }, 1000);
          } else {
            this.makeToastFaile('Thêm mới quyền thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    showVieOnPermissionOptions(){
      return (
        this.isEnableVieOnPermission
      );
    }
  },
};
</script>
